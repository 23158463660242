import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import styled, { css } from 'styled-components';

export const LogoArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RoadAssistance = styled.a<{ $isScrolled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 ${spacing.space8};
  line-height: ${spacing.space20};
  transition: font-size 0.3s ease-in-out, line-height 0.3s ease-in-out;
  color: ${nafColor.signature.black} !important;

  .strong {
    font-weight: 700;
  }

  &:hover {
    span {
      color: ${nafColor.primary.park} !important;
    }
  }

  @media (min-width: ${breakpoints.l}) {
    ${({ $isScrolled }) =>
      $isScrolled &&
      css`
        font-size: 0.9375rem;
        line-height: 1rem;
      `}
  }

  @media (max-width: ${breakpoints.l}) {
    color: ${nafColor.signature.black} !important;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: ${spacing.space8};
  }

  @media (width <= 400px) {
    font-size: 0.9375rem;
  }
`;

export const SeparatorLine = styled.div<{ $isScrolled: boolean }>`
  width: 1px;
  height: 40px;
  background-color: ${nafColor.signature.yellow};
  margin: 0 ${spacing.space24};
  transition: all 0.3s ease-in-out;

  ${({ $isScrolled }) =>
    $isScrolled &&
    css`
      height: 28px;
    `};

  @media (max-width: ${breakpoints.l}) {
    display: none;
  }
`;
