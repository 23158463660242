import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { Logo } from '../LogoDecorations/Logo';
import * as S from './Styles';

const LogoArea = ({ isScrolled, onNavClick }: { isScrolled?: boolean; onNavClick: () => void }) => (
  <S.LogoArea>
    <Logo isScrolled={isScrolled} onNavClick={onNavClick} />
    <S.RoadAssistance href="tel:+47-08505" aria-label="Ring oss 08 505" $isScrolled={isScrolled}>
      <Text tag="span" variant={TextVariant.Small}>
        Ring oss
      </Text>
      <Text tag="span" className="strong" variant={TextVariant.Small}>
        08 505
      </Text>
    </S.RoadAssistance>
    <S.SeparatorLine $isScrolled={isScrolled} />
  </S.LogoArea>
);

export default LogoArea;
