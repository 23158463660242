import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';

export const NavbarLinkItem = styled.div`
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  margin: 0 ${spacing.space16} 0 0 !important;

  span {
    transition: all 0.3s ease-in-out;
    color: ${nafColor.signature.black};
    line-height: 1.5;
  }

  a {
    padding: ${spacing.space4} ${spacing.space8};
    border-radius: ${spacing.space4};

    :hover {
      text-decoration: none;
      background-color: ${nafColor.signature.yellow40};
      border-radius: ${spacing.space4};
    }
  }

  &:hover {
    span {
      color: ${nafColor.signature.yellow40};
    }

    a {
      background-color: ${nafColor.signature.yellow40};
    }
  }

  @media (max-width: ${breakpoints.l}) {
    margin-left: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: row;
    justify-content: center;
    margin-left: ${spacing.space16} !important;
    margin-right: -${spacing.space4} !important;
  }
`;
